const week1 = {
    'videos': [
        {'contentName': 'Introduction - Week 1', 'contentDuraton' : 5},
        {'contentName': 'Generative AI & LLMs', 'contentDuraton' : 4},
        {'contentName': 'LLM use cases and tasks', 'contentDuraton' : 2},
        {'contentName': 'Text generation before transformers', 'contentDuraton' : 2},
        {'contentName': 'Transformers architecture', 'contentDuraton' : 7},
        {'contentName': 'Generating text with transformers', 'contentDuraton' : 5},
        {'contentName': 'Prompting and prompt engineering', 'contentDuraton' : 5},
        {'contentName': 'Generative configuration', 'contentDuraton' : 7},
        {'contentName': 'Generative AI project lifecycle', 'contentDuraton' : 4},
        {'contentName': 'Introduction to AWS labs', 'contentDuraton' : 5},
        {'contentName': 'Lab 1 walkthrough', 'contentDuraton' : 14},
        {'contentName': 'Pre-training large language models', 'contentDuraton' : 9},
        {'contentName': 'Computational challenges of training LLMs', 'contentDuraton' : 10},
        {'contentName': 'Optional video: Efficient multi-GPU compute strategies', 'contentDuraton' : 8},
        {'contentName': 'Scaling laws and compute-optimal models', 'contentDuraton' : 8},
        {'contentName': 'Pre-training for domain adaptation', 'contentDuraton' : 5},
    ],
    'books': [
        {'contentName': 'Contributor Acknowledgments', 'contentDuraton' : 10},
        {'contentName': 'Transformers: Attention is all you need', 'contentDuraton' : 10},
        {'contentName': 'Domain-specific training: BloombergGPT', 'contentDuraton' : 10},
        {'contentName': 'Week 1 resources', 'contentDuraton' : 10}
    ]
}

const week2 = {
    'videos': [
        {'contentName': 'Instruction fine-tuning', 'contentDuraton' : 7}, 
        {'contentName': 'Fine-tuning on a single task', 'contentDuraton' : 3}, 
        {'contentName': 'Multi-task instruction fine-tuning', 'contentDuraton' : 8}, 
        {'contentName': 'Model evaluation', 'contentDuraton' : 10}, 
        {'contentName': 'Benchmarks', 'contentDuraton' : 5}, 
        {'contentName': 'Parameter efficient fine-tuning (PEFT)', 'contentDuraton' : 4}, 
        {'contentName': 'PEFT techniques 1: LoRA', 'contentDuraton' : 8}, 
        {'contentName': 'PEFT techniques 2: Soft prompts', 'contentDuraton' : 7}, 
        {'contentName': 'Lab 2 walkthrough', 'contentDuraton' : 17},
    ],
    'books': [
        {'contentName': 'Scaling instruct models', 'contentDuraton' : 10}, 
        {'contentName': 'Week 2 Resources', 'contentDuraton' : 10}
    ]
}

const week3 = {
    'videos': [
        {'contentName': 'Aligning models with human values', 'contentDuraton' : 3}, 
        {'contentName': 'Reinforcement learning from human feedback (RLHF)', 'contentDuraton' : 8}, 
        {'contentName': 'RLHF: Obtaining feedback from humans', 'contentDuraton' : 6}, 
        {'contentName': 'RLHF: Reward model', 'contentDuraton' : 2}, 
        {'contentName': 'RLHF: Fine-tuning with reinforcement learning', 'contentDuraton' : 3}, 
        {'contentName': 'Optional video: Proximal policy optimization', 'contentDuraton' : 13}, 
        {'contentName': 'RLHF: Reward hacking', 'contentDuraton' : 6}, 
        {'contentName': 'Scaling human feedback', 'contentDuraton' : 5}, 
        {'contentName': 'Lab 3 walkthrough', 'contentDuraton' : 18}, 
        {'contentName': 'Model optimizations for deployment', 'contentDuraton' : 7}, 
        {'contentName': 'Generative AI Project Lifecycle Cheat Sheet', 'contentDuraton' : 2}, 
        {'contentName': 'Using the LLM in applications', 'contentDuraton' : 9}, 
        {'contentName': 'Interacting with external applications', 'contentDuraton' : 4}, 
        {'contentName': 'Helping LLMs reason and plan with chain-of-thought', 'contentDuraton' : 5}, 
        {'contentName': 'Program-aided language models (PAL)', 'contentDuraton' : 7}, 
        {'contentName': 'ReAct: Combining reasoning and action', 'contentDuraton' : 9}, 
        {'contentName': 'LLM application architectures', 'contentDuraton' : 5}, 
        {'contentName': 'Optional video: AWS Sagemaker JumpStart', 'contentDuraton' : 5}, 
        {'contentName': 'Responsible AI', 'contentDuraton' : 9}, 
        {'contentName': 'Course conclusion', 'contentDuraton' : 3},
    ],
    'books': [
        {'contentName': 'KL divergence', 'contentDuraton' : 10}, 
        {'contentName': 'ReAct: Reasoning and action', 'contentDuraton' : 10}, 
        {'contentName': 'Week 3 resources', 'contentDuraton' : 10}, 
        {'contentName': 'Lecture Notes Week 3', 'contentDuraton' : 5}, 
        {'contentName': 'Acknowledgments', 'contentDuraton' : 1}
    ]
}

export {week1, week2, week3}