import axios from "axios";

const baseURL = 'http://127.0.0.1:8000';

var getBaseUrl = () => {
    // custom base URL logic examples:
    // - to request a current URL without the search parameters part:
    let baseUrl = window.location.href.slice(0, -window.location.search.length);

    //// or to insert '/api' after the host part
    //let baseUrl = window.location.host + '/api' + window.location.pathname;

    // ensure slash at the end
    if (baseUrl[baseUrl.length - 1] != '/') baseUrl = baseUrl + '/';

    return baseUrl;
};


const axiosService = axios.create({ baseURL: getBaseUrl() });

const apiService = {
    get_answer: (question) => axiosService.post('/qa_bot', {"question": question})
};

export {
    apiService

}