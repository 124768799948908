import React from "react";
import { Module } from "../Module/Module";

import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import './dropdown.css';

function Dropdown({showSubtext, module_content }) {
    const minutsSum = data => {
        let sum = 0
        data.map(video => {
            sum += video.contentDuraton
        })

        return sum
    }

    return (
        <div className="dropdown">
            {
                showSubtext &&
                <>
                    <div className="content-title" >
                        <VideoLibraryIcon />  {module_content.videos.length} video  |  <WatchLaterIcon />  Total {minutsSum(module_content.videos)} minuts
                    </div>
                    {
                        module_content.videos.map((content, key) => (
                            <div className="content-field" key={key}>{content.contentName}  <span className="times">|  {content.contentDuraton} minutes</span></div>
                        ))
                    }

                    <div className="content-title" >
                        <LibraryBooksIcon />  {module_content.books.length} books  |  <WatchLaterIcon />  Total {minutsSum(module_content.books)} minuts
                    </div>
                    {
                        module_content.books.map((content, key) => (
                            <div className="content-field" key={key}>{content.contentName}  <span className="times">|  {content.contentDuraton} minutes</span></div>
                        ))
                    }
                </>
            }
        </div>
    );
}

export default Dropdown;
