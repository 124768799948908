import React, { useEffect,useState } from 'react';
import './App.css';
import Header from './Components/Header/Header';
import ChatBot from './Components/ChatBot/ChatBot';
import SearchBar from './Components/SearchBar/SearchBar';
import { Slider } from './Components/Slider/Slider';
import Alert from './Components/Alert/Alert';

function App() {
  const [showAlert, setShowAlert] = useState(false);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (isMobileDevice()) {
      setShowAlert(true); // Show the alert if on a mobile device
    }
  }, []);

  return (
    <div className="App">
      {showAlert && (
        <Alert/>
      )}
      {!isMobileDevice() && (<>
        <SearchBar />
        <Header />
        <Slider />
        <ChatBot />
      </>

      )}
    </div>
  );
}

export default App;
