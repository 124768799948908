import React, { useEffect, useState } from 'react';
// import { Widget, addResponseMessage } from 'react-chat-widget';
import { Widget, addResponseMessage } from 'unidatalab-chat-widget';

import 'unidatalab-chat-widget/lib/styles.css';
import { apiService } from '../../services/apiService';

import "./ChatBot.css"

const avatar = `${process.env.PUBLIC_URL}/avatar.svg`
const titleAvatar = `${process.env.PUBLIC_URL}/title_avatar.svg` 

function ChatBot() {

  const [messagesVisible, setMessagesVisible] = useState(true);

  useEffect(() => {
    addResponseMessage('Hello! How can I assist you today?');
  }, []);

  const handleNewUserMessage = async (question) => {

    const response = await apiService.get_answer(question);
    console.log(response)

    if (response.data.status === 'error') {
      console.log(JSON.stringify(response.data.error))
      addResponseMessage("Apologies, but our server is currently experiencing high demand. Please try again later. Thank you for your patience.")
    } else {

      addResponseMessage(response.data.answer);
    }


  };

  const toggleMessagesVisibility = () => {
    setMessagesVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className="chatbot-container">
      <div>
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          showCloseButton
          handleToggle={toggleMessagesVisibility}
          emojis={true}
          profileAvatar={avatar}
          titleAvatar={titleAvatar}
          title="StudyMate"
          subtitle=""
          senderPlaceHolder="Type..."
          showTimeStamp={false}
          
        />
      </div>
    </div>
  );
}

export default ChatBot;
