import React from 'react';
import './searchbar.css'
const SearchBar = () => {


    return (
        <div className='header-s' >
            {/* <img className='logo' src={`${process.env.PUBLIC_URL}/unidatalab_logo.jpeg`}/> */}
            <div className='logo'>
                <svg xmlns="http://www.w3.org/2000/svg" width="117" height="21" viewBox="0 0 117 21" fill="none">
                    <path opacity="0.2" d="M116.269 2.51562H78.025V20.289H116.269V2.51562Z" fill="#0FB2C4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M76.0803 19.017V13.5156H76.803V19.017H76.0803Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M76.2156 18.2891H81.717V19.0118H76.2156V18.2891Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M76.94 0.945312V6.44564H76.2173V0.945312H76.94Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.389 19.017V13.5156H115.112V19.017H114.389Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.977 19.0118H109.475V18.2891H114.977V19.0118Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.975 0.945312V6.44564H114.252V0.945312H114.975Z" fill="black" />
                    <path d="M1.43426 14.1046C0.571836 13.2471 0.140625 12.0232 0.140625 10.4328V4.35938H2.65467V10.3394C2.65467 12.2814 3.46049 13.2524 5.07214 13.2524C5.85603 13.2524 6.45491 13.0176 6.86879 12.5478C7.28267 12.078 7.48925 11.3419 7.48854 10.3394V4.35938H9.96757V10.4328C9.96757 12.0239 9.53636 13.2478 8.67393 14.1046C7.81151 14.9614 6.60561 15.3901 5.05622 15.3908C3.504 15.3908 2.29668 14.9621 1.43426 14.1046Z" fill="black" />
                    <path d="M22.3935 4.35938V15.2051H20.3283L14.9161 8.62019V15.2051H12.4424V4.35938H14.5192L19.9102 10.9443V4.35938H22.3935Z" fill="black" />
                    <path d="M24.9615 4.35938H27.4713V15.2051H24.9615V4.35938Z" fill="black" />
                    <path d="M30.0427 4.35938H34.97C36.1465 4.35938 37.1872 4.584 38.0921 5.03325C38.961 5.45169 39.692 6.11011 40.1986 6.93072C40.6995 7.74645 40.95 8.69695 40.95 9.78223C40.95 10.8675 40.6995 11.8177 40.1986 12.6327C39.6923 13.4536 38.9613 14.1121 38.0921 14.5302C37.188 14.9801 36.1472 15.2051 34.97 15.2051H30.0427V4.35938ZM34.8458 13.1442C35.9304 13.1442 36.7953 12.8421 37.4405 12.2379C38.0857 11.6337 38.4087 10.8152 38.4094 9.78223C38.4094 8.74931 38.0865 7.93075 37.4405 7.32656C36.7946 6.72237 35.9297 6.42027 34.8458 6.42027H32.5525V13.1442H34.8458Z" fill="black" />
                    <path d="M49.8283 12.881H44.7928L43.8303 15.2051H41.26L46.0939 4.35938H48.5729L53.4227 15.2051H50.7887L49.8283 12.881ZM49.0377 10.9708L47.3185 6.81823L45.5983 10.9708H49.0377Z" fill="black" />
                    <path d="M56.2581 6.40436H52.7869V4.35938H62.2381V6.40436H58.7679V15.2051H56.2581V6.40436Z" fill="black" />
                    <path d="M70.1869 12.881H65.1513L64.1962 15.2051H61.6185L66.4524 4.35938H68.9314L73.7812 15.2051H71.1473L70.1869 12.881ZM69.3962 10.9708L67.6771 6.81823L65.9568 10.975L69.3962 10.9708Z" fill="black" />
                    <path d="M80.4617 4.24219L82.9715 4.26341L82.8982 13.0631L88.3359 13.1087L88.319 15.1537L80.3715 15.0868L80.4617 4.24219Z" fill="black" />
                    <path d="M96.9838 12.904L91.9483 12.8615L90.9677 15.1782L88.3964 15.1559L93.3204 4.35156L95.7995 4.37173L100.558 15.2578L97.924 15.2355L96.9838 12.904ZM96.2091 10.9938L94.5239 6.8274L92.7697 10.9662L96.2091 10.9938Z" fill="black" />
                    <path d="M111.148 10.6738C111.495 11.1365 111.665 11.7025 111.66 12.3718C111.653 13.322 111.277 14.0496 110.535 14.5547C109.792 15.0599 108.713 15.3075 107.298 15.2976L101.689 15.2509L101.779 4.40625L107.078 4.44976C108.4 4.46179 109.413 4.72356 110.116 5.23507C110.451 5.46379 110.722 5.77352 110.905 6.13537C111.087 6.49722 111.175 6.89941 111.161 7.30445C111.167 7.79785 111.032 8.28281 110.774 8.70301C110.515 9.12322 110.143 9.46144 109.699 9.67841C110.275 9.84936 110.782 10.1977 111.148 10.6738ZM108.625 13.0934C108.963 12.8812 109.133 12.5469 109.137 12.0906C109.144 11.1921 108.482 10.7372 107.15 10.7258L104.221 10.7014L104.199 13.3821L107.128 13.4065C107.788 13.4143 108.287 13.311 108.625 13.0966V13.0934ZM104.258 6.32282L104.237 8.87931L106.731 8.90054C107.351 8.9062 107.822 8.80007 108.144 8.58217C108.301 8.479 108.429 8.33672 108.514 8.16929C108.6 8.00186 108.641 7.81501 108.633 7.62707C108.633 7.20258 108.475 6.88421 108.16 6.67197C107.844 6.45972 107.375 6.35006 106.752 6.34298L104.258 6.32282Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M76.2156 0.8125H81.717V1.53519H76.2156V0.8125Z" fill="black" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.977 1.53519H109.475V0.8125H114.977V1.53519Z" fill="black" />
                </svg>
            </div>
            <div className='search-box'>
                <input className='input' type="search" placeholder="Search courses..." />
            </div>

            <div className='nav'>
                <div >Courses</div>
                <div >Explore</div>
                <div>Degrees</div>
            </div>

        </div>
    );
};

export default SearchBar;
