import React from 'react';

import './slider.css'

const slidesData = [
    {
        id: 1,
        title: 'Finetuning Large Language Models',
        subtitle: 'by Google Cloud',
        image: `${process.env.PUBLIC_URL}/gcloud.png`,
    },
    {
        id: 2,
        title: 'Introduction to Large Language Models',
        subtitle: 'by DeepLearning.AI',
        image: `${process.env.PUBLIC_URL}/brain.png`,
    },
    {
        id: 3,
        title: 'Introduction to Large Language Models',
        subtitle: 'by DeepLearning.AI',
        image: `${process.env.PUBLIC_URL}/langchain.png`,
    },
    {
        id: 4,
        title: 'Generative Pre-trained Transformers',
        subtitle: 'by University of Glasgow',
        image: `${process.env.PUBLIC_URL}/column.png`,
    }
];

const Slider = () => {

    return (

        <div className='cards'>
            {slidesData.map(slide => (
                <div className='card' key={slide.id}>
                    <img className='image' src={slide.image} alt={slide.title} />
                    <div className='card-title'>{slide.title}</div>
                    <div className='card-subtitle'>{slide.subtitle}</div>
                </div>
            ))}

        </div>
    );
}

export {
    Slider
};